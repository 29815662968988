import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const Datenschutz = () => (
  <Layout >
    <div style={{margin: "auto", maxWidth: 680}}>
    <p>
    <br /></p>
<h1 class="adsimple-312060447">Datenschutzerkl&auml;rung</h1>
<h2 class="adsimple-312060447" id="einleitung-ueberblick">Einleitung und &Uuml;berblick</h2>
<p>Wir haben diese Datenschutzerkl&auml;rung (Fassung 14.07.2022-312060447) verfasst, um Ihnen gem&auml;&szlig; der Vorgaben der&nbsp;<a class="adsimple-312060447" href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&from=DE&tid=312060447#d1e2269-1-1" rel="noopener" target="_blank">Datenschutz-Grundverordnung (EU) 2016/679</a> und anwendbaren nationalen Gesetzen zu erkl&auml;ren, welche personenbezogenen Daten (kurz Daten) wir als Verantwortliche &ndash; und die von uns beauftragten Auftragsverarbeiter (z. B. Provider) &ndash; verarbeiten, zuk&uuml;nftig verarbeiten werden und welche rechtm&auml;&szlig;igen M&ouml;glichkeiten Sie haben. Die verwendeten Begriffe sind geschlechtsneutral zu verstehen.<br /><strong class="adsimple-312060447">Kurz gesagt:</strong> Wir informieren Sie umfassend &uuml;ber Daten, die wir &uuml;ber Sie verarbeiten.</p>
<p>Datenschutzerkl&auml;rungen klingen f&uuml;r gew&ouml;hnlich sehr technisch und verwenden juristische Fachbegriffe. Diese Datenschutzerkl&auml;rung soll Ihnen hingegen die wichtigsten Dinge so einfach und transparent wie m&ouml;glich beschreiben. Soweit es der Transparenz f&ouml;rderlich ist, werden technische <strong class="adsimple-312060447">Begriffe leserfreundlich erkl&auml;rt</strong>, Links zu weiterf&uuml;hrenden Informationen geboten und&nbsp;<strong class="adsimple-312060447">Grafiken</strong> zum Einsatz gebracht. Wir informieren damit in klarer und einfacher Sprache, dass wir im Rahmen unserer Gesch&auml;ftst&auml;tigkeiten nur dann personenbezogene Daten verarbeiten, wenn eine entsprechende gesetzliche Grundlage gegeben ist. Das ist sicher nicht m&ouml;glich, wenn man m&ouml;glichst knappe, unklare und juristisch-technische Erkl&auml;rungen abgibt, so wie sie im Internet oft Standard sind, wenn es um Datenschutz geht. Ich hoffe, Sie finden die folgenden Erl&auml;uterungen interessant und informativ und vielleicht ist die eine oder andere Information dabei, die Sie noch nicht kannten.<br />Wenn trotzdem Fragen bleiben, m&ouml;chten wir Sie bitten, sich an die unten bzw. im Impressum genannte verantwortliche Stelle zu wenden, den vorhandenen Links zu folgen und sich weitere Informationen auf Drittseiten anzusehen. Unsere Kontaktdaten finden Sie selbstverst&auml;ndlich auch im Impressum.</p>
<h2 class="adsimple-312060447" id="anwendungsbereich">Anwendungsbereich</h2>
<p>Diese Datenschutzerkl&auml;rung gilt f&uuml;r alle von uns im Unternehmen verarbeiteten personenbezogenen Daten und f&uuml;r alle personenbezogenen Daten, die von uns beauftragte Firmen (Auftragsverarbeiter) verarbeiten. Mit personenbezogenen Daten meinen wir Informationen im Sinne des Art. 4 Nr. 1 DSGVO wie zum Beispiel Name, E-Mail-Adresse und postalische Anschrift einer Person. Die Verarbeitung personenbezogener Daten sorgt daf&uuml;r, dass wir unsere Dienstleistungen und Produkte anbieten und abrechnen k&ouml;nnen, sei es online oder offline. Der Anwendungsbereich dieser Datenschutzerkl&auml;rung umfasst:</p>
<ul class="adsimple-312060447">
    <li class="adsimple-312060447">alle Onlineauftritte (Websites, Onlineshops), die wir betreiben</li>
    <li class="adsimple-312060447">Social Media Auftritte und E-Mail-Kommunikation</li>
    <li class="adsimple-312060447">mobile Apps f&uuml;r Smartphones und andere Ger&auml;te</li>
</ul>
<p><strong class="adsimple-312060447">Kurz gesagt:</strong> Die Datenschutzerkl&auml;rung gilt f&uuml;r alle Bereiche, in denen personenbezogene Daten im Unternehmen &uuml;ber die genannten Kan&auml;le strukturiert verarbeitet werden. Sollten wir au&szlig;erhalb dieser Kan&auml;le mit Ihnen in Rechtsbeziehungen eintreten, werden wir Sie gegebenenfalls gesondert informieren.</p>
<h2 class="adsimple-312060447" id="rechtsgrundlagen">Rechtsgrundlagen</h2>
<p>In der folgenden Datenschutzerkl&auml;rung geben wir Ihnen transparente Informationen zu den rechtlichen Grunds&auml;tzen und Vorschriften, also den Rechtsgrundlagen der Datenschutz-Grundverordnung, die uns erm&ouml;glichen, personenbezogene Daten zu verarbeiten.<br />Was das EU-Recht betrifft, beziehen wir uns auf die VERORDNUNG (EU) 2016/679 DES EUROP&Auml;ISCHEN PARLAMENTS UND DES RATES vom 27. April 2016. Diese Datenschutz-Grundverordnung der EU k&ouml;nnen Sie selbstverst&auml;ndlich online auf EUR-Lex, dem Zugang zum EU-Recht, unter <a class="adsimple-312060447" href="https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32016R0679">https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32016R0679</a> nachlesen.</p>
<p>Wir verarbeiten Ihre Daten nur, wenn mindestens eine der folgenden Bedingungen zutrifft:</p>
<ol>
    <li class="adsimple-312060447"><strong class="adsimple-312060447">Einwilligung</strong> (Artikel 6 Absatz 1 lit. a DSGVO): Sie haben uns Ihre Einwilligung gegeben, Daten zu einem bestimmten Zweck zu verarbeiten. Ein Beispiel w&auml;re die Speicherung Ihrer eingegebenen Daten eines Kontaktformulars.</li>
    <li class="adsimple-312060447"><strong class="adsimple-312060447">Vertrag</strong> (Artikel 6 Absatz 1 lit. b DSGVO): Um einen Vertrag oder vorvertragliche Verpflichtungen mit Ihnen zu erf&uuml;llen, verarbeiten wir Ihre Daten. Wenn wir zum Beispiel einen Kaufvertrag mit Ihnen abschlie&szlig;en, ben&ouml;tigen wir vorab personenbezogene Informationen.</li>
    <li class="adsimple-312060447"><strong class="adsimple-312060447">Rechtliche Verpflichtung</strong> (Artikel 6 Absatz 1 lit. c DSGVO): Wenn wir einer rechtlichen Verpflichtung unterliegen, verarbeiten wir Ihre Daten. Zum Beispiel sind wir gesetzlich verpflichtet Rechnungen f&uuml;r die Buchhaltung aufzuheben. Diese enthalten in der Regel personenbezogene Daten.</li>
    <li class="adsimple-312060447"><strong class="adsimple-312060447">Berechtigte Interessen</strong> (Artikel 6 Absatz 1 lit. f DSGVO): Im Falle berechtigter Interessen, die Ihre Grundrechte nicht einschr&auml;nken, behalten wir uns die Verarbeitung personenbezogener Daten vor. Wir m&uuml;ssen zum Beispiel gewisse Daten verarbeiten, um unsere Website sicher und wirtschaftlich effizient betreiben zu k&ouml;nnen. Diese Verarbeitung ist somit ein berechtigtes Interesse.</li>
</ol>
<p>Weitere Bedingungen wie die Wahrnehmung von Aufnahmen im &ouml;ffentlichen Interesse und Aus&uuml;bung &ouml;ffentlicher Gewalt sowie dem Schutz lebenswichtiger Interessen treten bei uns in der Regel nicht auf. Soweit eine solche Rechtsgrundlage doch einschl&auml;gig sein sollte, wird diese an der entsprechenden Stelle ausgewiesen.</p>
<p>Zus&auml;tzlich zu der EU-Verordnung gelten auch noch nationale Gesetze:</p>
<ul class="adsimple-312060447">
    <li class="adsimple-312060447">In <strong class="adsimple-312060447">&Ouml;sterreich</strong> ist dies das Bundesgesetz zum Schutz nat&uuml;rlicher Personen bei der Verarbeitung personenbezogener Daten (<strong class="adsimple-312060447">Datenschutzgesetz</strong>), kurz <strong class="adsimple-312060447">DSG</strong>.</li>
    <li class="adsimple-312060447">In <strong class="adsimple-312060447">Deutschland</strong> gilt das <strong class="adsimple-312060447">Bundesdatenschutzgesetz</strong>, kurz<strong class="adsimple-312060447">&nbsp;BDSG</strong>.</li>
</ul>
<p>Sofern weitere regionale oder nationale Gesetze zur Anwendung kommen, informieren wir Sie in den folgenden Abschnitten dar&uuml;ber.</p>
<h2 class="adsimple-312060447" id="kontaktdaten-verantwortliche">Kontaktdaten des Verantwortlichen</h2>
<p>Sollten Sie Fragen zum Datenschutz oder zur Verarbeitung personenbezogener Daten haben, finden Sie nachfolgend die Kontaktdaten der verantwortlichen Person bzw. Stelle:<br /><span class="adsimple-312060447" style={{fontWeight: 400}}>Niklas Oelze<br />&nbsp;Lausitzer Weg 7, 21465 Reinbek, Deutschland</span><br /><span style={{fontWeight: 400}}>Vertretungsberechtigt: Markus Mustermann</span><br />E-Mail:&nbsp;<a href="mailto:niklas.oelze.biz@gmail.com">niklas.oelze.biz@gmail.com</a><br />Telefon:&nbsp;<a href="tel:+49 4711 12345">+49 4711 12345</a><br />Impressum: <a href="https://www.hotwheels.autos/impressum/">https://www.hotwheels.autos/impressum/</a></p>
<h2 class="adsimple-312060447" id="speicherdauer">Speicherdauer</h2>
<p>Dass wir personenbezogene Daten nur so lange speichern, wie es f&uuml;r die Bereitstellung unserer Dienstleistungen und Produkte unbedingt notwendig ist, gilt als generelles Kriterium bei uns. Das bedeutet, dass wir personenbezogene Daten l&ouml;schen, sobald der Grund f&uuml;r die Datenverarbeitung nicht mehr vorhanden ist. In einigen F&auml;llen sind wir gesetzlich dazu verpflichtet, bestimmte Daten auch nach Wegfall des urspr&uuml;ngliches Zwecks zu speichern, zum Beispiel zu Zwecken der Buchf&uuml;hrung.</p>
<p>Sollten Sie die L&ouml;schung Ihrer Daten w&uuml;nschen oder die Einwilligung zur Datenverarbeitung widerrufen, werden die Daten so rasch wie m&ouml;glich und soweit keine Pflicht zur Speicherung besteht, gel&ouml;scht.</p>
<p>&Uuml;ber die konkrete Dauer der jeweiligen Datenverarbeitung informieren wir Sie weiter unten, sofern wir weitere Informationen dazu haben.</p>
<h2 class="adsimple-312060447" id="rechte-dsgvo">Rechte laut Datenschutz-Grundverordnung</h2>
<p>Gem&auml;&szlig; Artikel 13, 14 DSGVO informieren wir Sie &uuml;ber die folgenden Rechte, die Ihnen zustehen, damit es zu einer fairen und transparenten Verarbeitung von Daten kommt:</p>
<ul class="adsimple-312060447">
    <li class="adsimple-312060447">Sie haben laut Artikel 15 DSGVO ein Auskunftsrecht dar&uuml;ber, ob wir Daten von Ihnen verarbeiten. Sollte das zutreffen, haben Sie Recht darauf eine Kopie der Daten zu erhalten und die folgenden Informationen zu erfahren:<ul class="adsimple-312060447">
            <li class="adsimple-312060447">zu welchem Zweck wir die Verarbeitung durchf&uuml;hren;</li>
            <li class="adsimple-312060447">die Kategorien, also die Arten von Daten, die verarbeitet werden;</li>
            <li class="adsimple-312060447">wer diese Daten erh&auml;lt und wenn die Daten an Drittl&auml;nder &uuml;bermittelt werden, wie die Sicherheit garantiert werden kann;</li>
            <li class="adsimple-312060447">wie lange die Daten gespeichert werden;</li>
            <li class="adsimple-312060447">das Bestehen des Rechts auf Berichtigung, L&ouml;schung oder Einschr&auml;nkung der Verarbeitung und dem Widerspruchsrecht gegen die Verarbeitung;</li>
            <li class="adsimple-312060447">dass Sie sich bei einer Aufsichtsbeh&ouml;rde beschweren k&ouml;nnen (Links zu diesen Beh&ouml;rden finden Sie weiter unten);</li>
            <li class="adsimple-312060447">die Herkunft der Daten, wenn wir sie nicht bei Ihnen erhoben haben;</li>
            <li class="adsimple-312060447">ob Profiling durchgef&uuml;hrt wird, ob also Daten automatisch ausgewertet werden, um zu einem pers&ouml;nlichen Profil von Ihnen zu gelangen.</li>
        </ul>
    </li>
    <li class="adsimple-312060447">Sie haben laut Artikel 16 DSGVO ein Recht auf Berichtigung der Daten, was bedeutet, dass wir Daten richtig stellen m&uuml;ssen, falls Sie Fehler finden.</li>
    <li class="adsimple-312060447">Sie haben laut Artikel 17 DSGVO das Recht auf L&ouml;schung (&bdquo;Recht auf Vergessenwerden&ldquo;), was konkret bedeutet, dass Sie die L&ouml;schung Ihrer Daten verlangen d&uuml;rfen.</li>
    <li class="adsimple-312060447">Sie haben laut Artikel 18 DSGVO das Recht auf Einschr&auml;nkung der Verarbeitung, was bedeutet, dass wir die Daten nur mehr speichern d&uuml;rfen aber nicht weiter verwenden.</li>
    <li class="adsimple-312060447">Sie haben laut Artikel 20 DSGVO das Recht auf Daten&uuml;bertragbarkeit, was bedeutet, dass wir Ihnen auf Anfrage Ihre Daten in einem g&auml;ngigen Format zur Verf&uuml;gung stellen.</li>
    <li class="adsimple-312060447">Sie haben laut Artikel 21 DSGVO ein Widerspruchsrecht, welches nach Durchsetzung eine &Auml;nderung der Verarbeitung mit sich bringt.<ul class="adsimple-312060447">
            <li class="adsimple-312060447">Wenn die Verarbeitung Ihrer Daten auf Artikel 6 Abs. 1 lit. e (&ouml;ffentliches Interesse, Aus&uuml;bung &ouml;ffentlicher Gewalt) oder Artikel 6 Abs. 1 lit. f (berechtigtes Interesse) basiert, k&ouml;nnen Sie gegen die Verarbeitung Widerspruch einlegen. Wir pr&uuml;fen danach so rasch wie m&ouml;glich, ob wir diesem Widerspruch rechtlich nachkommen k&ouml;nnen.</li>
            <li class="adsimple-312060447">Werden Daten verwendet, um Direktwerbung zu betreiben, k&ouml;nnen Sie jederzeit gegen diese Art der Datenverarbeitung widersprechen. Wir d&uuml;rfen Ihre Daten danach nicht mehr f&uuml;r Direktmarketing verwenden.</li>
            <li class="adsimple-312060447">Werden Daten verwendet, um Profiling zu betreiben, k&ouml;nnen Sie jederzeit gegen diese Art der Datenverarbeitung widersprechen. Wir d&uuml;rfen Ihre Daten danach nicht mehr f&uuml;r Profiling verwenden.</li>
        </ul>
    </li>
    <li class="adsimple-312060447">Sie haben laut Artikel 22 DSGVO unter Umst&auml;nden das Recht, nicht einer ausschlie&szlig;lich auf einer automatisierten Verarbeitung (zum Beispiel Profiling) beruhenden Entscheidung unterworfen zu werden.</li>
    <li class="adsimple-312060447">Sie haben laut Artikel 77 DSGVO das Recht auf Beschwerde. Das hei&szlig;t, Sie k&ouml;nnen sich jederzeit bei der Datenschutzbeh&ouml;rde beschweren, wenn Sie der Meinung sind, dass die Datenverarbeitung von personenbezogenen Daten gegen die DSGVO verst&ouml;&szlig;t.</li>
</ul>
<p><strong class="adsimple-312060447">Kurz gesagt:</strong> Sie haben Rechte &ndash; z&ouml;gern Sie nicht, die oben gelistete verantwortliche Stelle bei uns zu kontaktieren!</p>
<p>Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verst&ouml;&szlig;t oder Ihre datenschutzrechtlichen Anspr&uuml;che in sonst einer Weise verletzt worden sind, k&ouml;nnen Sie sich bei der Aufsichtsbeh&ouml;rde beschweren. Diese ist f&uuml;r &Ouml;sterreich die Datenschutzbeh&ouml;rde, deren Website Sie unter <a class="adsimple-312060447" href="https://www.dsb.gv.at/?tid=312060447" rel="noopener" target="_blank">https://www.dsb.gv.at/</a> finden. In Deutschland gibt es f&uuml;r jedes Bundesland einen Datenschutzbeauftragten. F&uuml;r n&auml;here Informationen k&ouml;nnen Sie sich an die <a class="adsimple-312060447" href="https://www.bfdi.bund.de/DE/Home/home_node.html" rel="noopener" target="_blank">Bundesbeauftragte f&uuml;r den Datenschutz und die Informationsfreiheit (BfDI)</a> wenden. F&uuml;r unser Unternehmen ist die folgende lokale Datenschutzbeh&ouml;rde zust&auml;ndig:</p>
<h2 class="adsimple-312060447" id="schleswig-holstein-datenschutzbehoerde">Schleswig-Holstein Datenschutzbeh&ouml;rde</h2>
<p><strong class="adsimple-312060447">Landesbeauftragte f&uuml;r Datenschutz:</strong> Marit Hansen<br /><strong class="adsimple-312060447">Adresse:&nbsp;</strong>Holstenstra&szlig;e 98, 24103 Kiel<br /><strong class="adsimple-312060447">Telefonnr.:</strong> 04 31/988-12 00<br /><strong class="adsimple-312060447">E-Mail-Adresse:</strong> mail@datenschutzzentrum.de<br /><strong class="adsimple-312060447">Website:&nbsp;</strong> <a class="adsimple-312060447" href="https://www.datenschutzzentrum.de/?tid=312060447" rel="noopener" target="_blank">https://www.datenschutzzentrum.de/</a></p>
<h2 class="adsimple-312060447" id="webhosting-einleitung">Webhosting Einleitung</h2>
<table border="1" cellpadding="15">
    <tbody>
        <tr>
            <td><strong class="adsimple-312060447">Webhosting Zusammenfassung</strong><br />👥 Betroffene: Besucher der Website<br />🤝 Zweck: professionelles Hosting der Website und Absicherung des Betriebs<br />📓 Verarbeitete Daten: IP-Adresse, Zeitpunkt des Websitebesuchs, verwendeter Browser und weitere Daten. Mehr Details dazu finden Sie weiter unten bzw. beim jeweils eingesetzten Webhosting Provider.<br />📅 Speicherdauer: abh&auml;ngig vom jeweiligen Provider, aber in der Regel 2 Wochen<br />⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit.f DSGVO (Berechtigte Interessen)</td>
        </tr>
    </tbody>
</table>
<h3 class="adsimple-312060447">Was ist Webhosting?</h3>
<p>Wenn Sie heutzutage Websites besuchen, werden gewisse Informationen &ndash; auch personenbezogene Daten &ndash; automatisch erstellt und gespeichert, so auch auf dieser Website. Diese Daten sollten m&ouml;glichst sparsam und nur mit Begr&uuml;ndung verarbeitet werden. Mit Website meinen wir &uuml;brigens die Gesamtheit aller Webseiten auf einer Domain, d.h. alles von der Startseite (Homepage) bis hin zur aller letzten Unterseite (wie dieser hier). Mit Domain meinen wir zum Beispiel beispiel.de oder musterbeispiel.com.</p>
<p>Wenn Sie eine Website auf einem Computer, Tablet oder Smartphone ansehen m&ouml;chten, verwenden Sie daf&uuml;r ein Programm, das sich Webbrowser nennt. Sie kennen vermutlich einige Webbrowser beim Namen: Google Chrome, Microsoft Edge, Mozilla Firefox und Apple Safari. Wir sagen kurz Browser oder Webbrowser dazu.</p>
<p>Um die Website anzuzeigen, muss sich der Browser zu einem anderen Computer verbinden, wo der Code der Website gespeichert ist: dem Webserver. Der Betrieb eines Webservers ist eine komplizierte und aufwendige Aufgabe, weswegen dies in der Regel von professionellen Anbietern, den Providern, &uuml;bernommen wird. Diese bieten Webhosting an und sorgen damit f&uuml;r eine verl&auml;ssliche und fehlerfreie Speicherung der Daten von Websites. Eine ganze Menge Fachbegriffe, aber bitte bleiben Sie dran, es wird noch besser!</p>
<p>Bei der Verbindungsaufnahme des Browsers auf Ihrem Computer (Desktop, Laptop, Tablet oder Smartphone) und w&auml;hrend der Daten&uuml;bertragung zu und vom Webserver kann es zu einer Verarbeitung personenbezogener Daten kommen. Einerseits speichert Ihr Computer Daten, andererseits muss auch der Webserver Daten eine Zeit lang speichern, um einen ordentlichen Betrieb zu gew&auml;hrleisten.</p>
<p>Ein Bild sagt mehr als tausend Worte, daher zeigt folgende Grafik zur Veranschaulichung das Zusammenspiel zwischen Browser, dem Internet und dem Hosting-Provider.</p>
<p><img src="https://www.adsimple.at/wp-content/uploads/2018/03/browser-und-webserver.svg" alt="Browser und Webserver" width="100%" /></p>
<h3 class="adsimple-312060447">Warum verarbeiten wir personenbezogene Daten?</h3>
<p>Die Zwecke der Datenverarbeitung sind:</p>
<ol>
    <li class="adsimple-312060447">Professionelles Hosting der Website und Absicherung des Betriebs</li>
    <li class="adsimple-312060447">zur Aufrechterhaltung der Betriebs- und IT-Sicherheit</li>
    <li class="adsimple-312060447">Anonyme Auswertung des Zugriffsverhaltens zur Verbesserung unseres Angebots und ggf. zur Strafverfolgung bzw. Verfolgung von Anspr&uuml;chen</li>
</ol>
<h3 class="adsimple-312060447">Welche Daten werden verarbeitet?</h3>
<p>Auch w&auml;hrend Sie unsere Website jetzt gerade besuchen, speichert unser Webserver, das ist der Computer auf dem diese Webseite gespeichert ist, in der Regel automatisch Daten wie</p>
<ul class="adsimple-312060447">
    <li class="adsimple-312060447">die komplette Internetadresse (URL) der aufgerufenen Webseite</li>
    <li class="adsimple-312060447">Browser und Browserversion (z. B. Chrome 87)</li>
    <li class="adsimple-312060447">das verwendete Betriebssystem (z. B. Windows 10)</li>
    <li class="adsimple-312060447">die Adresse (URL) der zuvor besuchten Seite (Referrer URL) (z. B. <a class="adsimple-312060447" href="https://www.beispielquellsite.de/vondabinichgekommen/" rel="follow noopener" target="_blank">https://www.beispielquellsite.de/vondabinichgekommen/</a>)</li>
    <li class="adsimple-312060447">den Hostnamen und die IP-Adresse des Ger&auml;ts von welchem aus zugegriffen wird (z. B. COMPUTERNAME und 194.23.43.121)</li>
    <li class="adsimple-312060447">Datum und Uhrzeit</li>
    <li class="adsimple-312060447">in Dateien, den sogenannten Webserver-Logfiles</li>
</ul>
<h3 class="adsimple-312060447">Wie lange werden Daten gespeichert?</h3>
<p>In der Regel werden die oben genannten Daten zwei Wochen gespeichert und danach automatisch gel&ouml;scht. Wir geben diese Daten nicht weiter, k&ouml;nnen jedoch nicht ausschlie&szlig;en, dass diese Daten beim Vorliegen von rechtswidrigem Verhalten von Beh&ouml;rden eingesehen werden.</p>
<p><strong class="adsimple-312060447">Kurz gesagt:</strong> Ihr Besuch wird durch unseren Provider (Firma, die unsere Website auf speziellen Computern (Servern) laufen l&auml;sst), protokolliert, aber wir geben Ihre Daten nicht ohne Zustimmung weiter!</p>
<h3 class="adsimple-312060447">Rechtsgrundlage</h3>
<p>Die Rechtm&auml;&szlig;igkeit der Verarbeitung personenbezogener Daten im Rahmen des Webhosting ergibt sich aus Art. 6 Abs. 1 lit. f DSGVO (Wahrung der berechtigten Interessen), denn die Nutzung von professionellem Hosting bei einem Provider ist notwendig, um das Unternehmen im Internet sicher und nutzerfreundlich pr&auml;sentieren und Angriffe und Forderungen hieraus gegebenenfalls verfolgen zu k&ouml;nnen.</p>
<p>Zwischen uns und dem Hosting-Provider besteht in der Regel ein Vertrag &uuml;ber die Auftragsverarbeitung gem&auml;&szlig; Art. 28 f. DSGVO, der die Einhaltung von Datenschutz gew&auml;hrleistet und Datensicherheit garantiert.</p>
<h2 class="adsimple-312060447" id="netlify-datenschutzerklaerung">Netlify Datenschutzerkl&auml;rung</h2>
<p>Wir verwenden f&uuml;r unsere Website den Webhosting-Anbieter Netlify. Dienstanbieter ist das amerikanische Unternehmen Netlify Inc., 2325 3rd Street, Suite 29, San Francisco, CA 94104, USA.</p>
<p>Netlify verarbeitet Daten von Ihnen u.a. auch in den USA. Wir weisen darauf hin, dass nach Meinung des Europ&auml;ischen Gerichtshofs derzeit kein angemessenes Schutzniveau f&uuml;r den Datentransfer in die USA besteht. Dies kann mit verschiedenen Risiken f&uuml;r die Rechtm&auml;&szlig;igkeit und Sicherheit der Datenverarbeitung einhergehen.</p>
<p>Als Grundlage der Datenverarbeitung bei Empf&auml;ngern mit Sitz in Drittstaaten (au&szlig;erhalb der Europ&auml;ischen Union, Island, Liechtenstein, Norwegen, also insbesondere in den USA) oder einer Datenweitergabe dorthin verwendet Netlify sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses &ndash; SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass Ihre Daten auch dann den europ&auml;ischen Datenschutzstandards entsprechen, wenn diese in Drittl&auml;nder (wie beispielsweise in die USA) &uuml;berliefert und dort gespeichert werden. Durch diese Klauseln verpflichtet sich Netlify, bei der Verarbeitung Ihrer relevanten Daten, das europ&auml;ische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchf&uuml;hrungsbeschluss der EU-Kommission. Sie finden den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a class="adsimple-312060447" href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a></p>
<p>Die Netlify Datenverarbeitungsbedingung (Data Protection Agreement), welche den Standardvertragsklauseln entspricht, finden Sie unter <a class="adsimple-312060447" href="https://www.netlify.com/v3/static/pdf/netlify-dpa.pdf">https://www.netlify.com/v3/static/pdf/netlify-dpa.pdf</a>.</p>
<p>Mehr &uuml;ber die Daten, die durch die Verwendung von Netlify verarbeitet werden, erfahren Sie in der Privacy Policy auf <a class="adsimple-312060447" href="https://www.netlify.com/privacy/?tid=312060447">https://www.netlify.com/privacy/</a>.</p>
<h2 class="adsimple-312060447" id="web-analytics-einleitung">Web Analytics Einleitung</h2>
<table border="1" cellpadding="15">
    <tbody>
        <tr>
            <td><strong class="adsimple-312060447">Web Analytics Datenschutzerkl&auml;rung Zusammenfassung</strong><br />👥 Betroffene: Besucher der Website<br />🤝 Zweck: Auswertung der Besucherinformationen zur Optimierung des Webangebots.<br />📓 Verarbeitete Daten: Zugriffsstatistiken, die Daten wie Standorte der Zugriffe, Ger&auml;tedaten, Zugriffsdauer und Zeitpunkt, Navigationsverhalten, Klickverhalten und IP-Adressen enthalten. Mehr Details dazu finden Sie beim jeweils eingesetzten Web Analytics Tool.<br />📅 Speicherdauer: abh&auml;ngig vom eingesetzten Web-Analytics-Tool<br />⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</td>
        </tr>
    </tbody>
</table>
<h3 class="adsimple-312060447">Was ist Web Analytics?</h3>
<p>Wir verwenden auf unserer Website Software zur Auswertung des Verhaltens der Website-Besucher, kurz Web Analytics oder Web-Analyse genannt. Dabei werden Daten gesammelt, die der jeweilige Analytic-Tool-Anbieter (auch Trackingtool genannt) speichert, verwaltet und verarbeitet. Mit Hilfe der Daten werden Analysen &uuml;ber das Nutzerverhalten auf unserer Website erstellt und uns als Websitebetreiber zur Verf&uuml;gung gestellt. Zus&auml;tzlich bieten die meisten Tools verschiedene Testm&ouml;glichkeiten an. So k&ouml;nnen wir etwa testen, welche Angebote oder Inhalte bei unseren Besuchern am besten ankommen. Daf&uuml;r zeigen wir Ihnen f&uuml;r einen begrenzten Zeitabschnitt zwei verschiedene Angebote. Nach dem Test (sogenannter A/B-Test) wissen wir, welches Produkt bzw. welcher Inhalt unsere Websitebesucher interessanter finden. F&uuml;r solche Testverfahren, wie auch f&uuml;r andere Analytics-Verfahren, k&ouml;nnen auch Userprofile erstellt werden und die Daten in Cookies gespeichert werden.</p>
<h3 class="adsimple-312060447">Warum betreiben wir Web Analytics?</h3>
<p>Mit unserer Website haben wir ein klares Ziel vor Augen: wir wollen f&uuml;r unsere Branche das beste Webangebot auf dem Markt liefern. Um dieses Ziel zu erreichen, wollen wir einerseits das beste und interessanteste Angebot bieten und andererseits darauf achten, dass Sie sich auf unserer Website rundum wohlf&uuml;hlen. Mit Hilfe von Webanalyse-Tools k&ouml;nnen wir das Verhalten unserer Websitebesucher genauer unter die Lupe nehmen und dann entsprechend unser Webangebot f&uuml;r Sie und uns verbessern. So k&ouml;nnen wir beispielsweise erkennen wie alt unsere Besucher durchschnittlich sind, woher sie kommen, wann unsere Website am meisten besucht wird oder welche Inhalte oder Produkte besonders beliebt sind. All diese Informationen helfen uns die Website zu optimieren und somit bestens an Ihre Bed&uuml;rfnisse, Interessen und W&uuml;nsche anzupassen.</p>
<h3 class="adsimple-312060447">Welche Daten werden verarbeitet?</h3>
<p>Welche Daten genau gespeichert werden, h&auml;ngt nat&uuml;rlich von den verwendeten Analyse-Tools ab. Doch in der Regel wird zum Beispiel gespeichert, welche Inhalte Sie auf unserer Website ansehen, auf welche Buttons oder Links Sie klicken, wann Sie eine Seite aufrufen, welchen Browser sie verwenden, mit welchem Ger&auml;t (PC, Tablet, Smartphone usw.) Sie die Website besuchen oder welches Computersystem Sie verwenden. Wenn Sie damit einverstanden waren, dass auch Standortdaten erhoben werden d&uuml;rfen, k&ouml;nnen auch diese durch den Webanalyse-Tool-Anbieter verarbeitet werden.</p>
<p>Zudem wird auch Ihre IP-Adresse gespeichert. Gem&auml;&szlig; der Datenschutz-Grundverordnung (DSGVO) sind IP-Adressen personenbezogene Daten. Ihre IP-Adresse wird allerdings in der Regel pseudonymisiert (also in unkenntlicher und gek&uuml;rzter Form) gespeichert. F&uuml;r den Zweck der Tests, der Webanalyse und der Weboptimierung werden grunds&auml;tzlich keine direkten Daten, wie etwa Ihr Name, Ihr Alter, Ihre Adresse oder Ihre E-Mail-Adresse gespeichert. All diese Daten werden, sofern sie erhoben werden, pseudonymisiert gespeichert. So k&ouml;nnen Sie als Person nicht identifiziert werden.</p>
<p>Das folgende Beispiel zeigt schematisch die Funktionsweise von Google Analytics als Beispiel f&uuml;r client-basiertes Webtracking mit Java-Script-Code.</p>
<p><img src="https://www.adsimple.at/wp-content/uploads/2021/04/google-analytics-dataflow.svg" alt="Schematischer Datenfluss bei Google Analytics" width="100%" /></p>
<p>Wie lange die jeweiligen Daten gespeichert werden, h&auml;ngt immer vom Anbieter ab. Manche Cookies speichern Daten nur f&uuml;r ein paar Minuten bzw. bis Sie die Website wieder verlassen, andere Cookies k&ouml;nnen Daten &uuml;ber mehrere Jahre speichern.</p>
<h3 class="adsimple-312060447"><span class="adsimple-312060447" data-sheets-userformat='{"2":769,"3":{"1":0},"11":4,"12":0}' data-sheets-value='{"1":2,"2":"Wo und wie lange werden Daten gespeichert?"}'>Dauer der Datenverarbeitung</span></h3>
<p>&Uuml;ber die Dauer der Datenverarbeitung informieren wir Sie weiter unten, sofern wir weitere Informationen dazu haben. Generell verarbeiten wir personenbezogene Daten nur so lange wie es f&uuml;r die Bereitstellung unserer Dienstleistungen und Produkte unbedingt notwendig ist. Wenn es, wie zum Beispiel im Fall von Buchhaltung, gesetzlich vorgeschrieben ist, kann diese Speicherdauer auch &uuml;berschritten werden.</p>
<h3 class="adsimple-312060447">Widerspruchsrecht</h3>
<p>Sie haben auch jederzeit das Recht und die M&ouml;glichkeit Ihre Einwilligung zur Verwendung von Cookies bzw. Drittanbietern zu widerrufen. Das funktioniert entweder &uuml;ber unser Cookie-Management-Tool oder &uuml;ber andere Opt-Out-Funktionen. Zum Beispiel k&ouml;nnen Sie auch die Datenerfassung durch Cookies verhindern, indem Sie in Ihrem Browser die Cookies verwalten, deaktivieren oder l&ouml;schen.</p>
<h3 class="adsimple-312060447">Rechtsgrundlage</h3>
<p>Der Einsatz von Web-Analytics setzt Ihre Einwilligung voraus, welche wir mit unserem Cookie Popup eingeholt haben. Diese Einwilligung stellt laut<strong class="adsimple-312060447">&nbsp;Art. 6 Abs. 1 lit. a DSGVO (Einwilligung)</strong> die Rechtsgrundlage f&uuml;r die Verarbeitung personenbezogener Daten, wie sie bei der Erfassung durch Web-Analytics Tools vorkommen kann, dar.</p>
<p>Zus&auml;tzlich zur Einwilligung besteht von unserer Seite ein berechtigtes Interesse daran, das Verhalten der Websitebesucher zu analysieren und so unser Angebot technisch und wirtschaftlich zu verbessern. Mit Hilfe von Web-Analytics erkennen wir Fehler der Website, k&ouml;nnen Attacken identifizieren und die Wirtschaftlichkeit verbessern. Die Rechtsgrundlage daf&uuml;r ist <strong class="adsimple-312060447">Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</strong>. Wir setzen die Tools gleichwohl nur ein, soweit sie eine Einwilligung erteilt haben.</p>
<p>Da bei Web-Analytics-Tools Cookies zum Einsatz kommen, empfehlen wir Ihnen auch das Lesen unserer allgemeinen Datenschutzerkl&auml;rung zu Cookies. Um zu erfahren, welche Daten von Ihnen genau gespeichert und verarbeitet werden, sollten Sie die Datenschutzerkl&auml;rungen der jeweiligen Tools durchlesen.</p>
<p>Informationen zu speziellen Web-Analytics-Tools, erhalten Sie &ndash; sofern vorhanden &ndash; in den folgenden Abschnitten.</p>
<h2 class="adsimple-312060447" id="google-analytics-datenschutzerklaerung">Google Analytics Datenschutzerkl&auml;rung</h2>
<table border="1" cellpadding="15">
    <tbody>
        <tr>
            <td><strong class="adsimple-312060447">Google Analytics Datenschutzerkl&auml;rung Zusammenfassung</strong><br />👥 Betroffene: Besucher der Website<br />🤝 Zweck: Auswertung der Besucherinformationen zur Optimierung des Webangebots.<br />📓 Verarbeitete Daten: Zugriffsstatistiken, die Daten wie Standorte der Zugriffe, Ger&auml;tedaten, Zugriffsdauer und Zeitpunkt, Navigationsverhalten, Klickverhalten und IP-Adressen enthalten. Mehr Details dazu finden Sie weiter unten in dieser Datenschutzerkl&auml;rung.<br />📅 Speicherdauer: abh&auml;ngig von den verwendeten Properties<br />⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</td>
        </tr>
    </tbody>
</table>
<h3 class="adsimple-312060447">Was ist Google Analytics?</h3>
<p>Wir verwenden auf unserer Website das Analyse-Tracking Tool Google Analytics (GA) des amerikanischen Unternehmens Google Inc. F&uuml;r den europ&auml;ischen Raum ist das Unternehmen Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland) f&uuml;r alle Google-Dienste verantwortlich. Google Analytics sammelt Daten &uuml;ber Ihre Handlungen auf unserer Website. Wenn Sie beispielsweise einen Link anklicken, wird diese Aktion in einem Cookie gespeichert und an Google Analytics versandt. Mithilfe der Berichte, die wir von Google Analytics erhalten, k&ouml;nnen wir unsere Website und unser Service besser an Ihre W&uuml;nsche anpassen. Im Folgenden gehen wir n&auml;her auf das Tracking-Tool ein und informieren Sie vor allem dar&uuml;ber, welche Daten gespeichert werden und wie Sie das verhindern k&ouml;nnen.</p>
<p>Google Analytics ist ein Trackingtool, das der Datenverkehrsanalyse unserer Website dient. Damit Google Analytics funktioniert, wird ein Tracking-Code in den Code unserer Website eingebaut. Wenn Sie unsere Website besuchen, zeichnet dieser Code verschiedene Handlungen auf, die Sie auf unserer Website ausf&uuml;hren. Sobald Sie unsere Website verlassen, werden diese Daten an die Google-Analytics-Server gesendet und dort gespeichert.</p>
<p>Google verarbeitet die Daten und wir bekommen Berichte &uuml;ber Ihr Userverhalten. Dabei kann es sich unter anderem um folgende Berichte handeln:</p>
<ul class="adsimple-312060447">
    <li class="adsimple-312060447">Zielgruppenberichte: &Uuml;ber Zielgruppenberichte lernen wir unsere User besser kennen und wissen genauer, wer sich f&uuml;r unser Service interessiert.</li>
    <li class="adsimple-312060447">Anzeigeberichte: Durch Anzeigeberichte k&ouml;nnen wir unsere Onlinewerbung leichter analysieren und verbessern.</li>
    <li class="adsimple-312060447">Akquisitionsberichte: Akquisitionsberichte geben uns hilfreiche Informationen dar&uuml;ber, wie wir mehr Menschen f&uuml;r unser Service begeistern k&ouml;nnen.</li>
    <li class="adsimple-312060447">Verhaltensberichte: Hier erfahren wir, wie Sie mit unserer Website interagieren. Wir k&ouml;nnen nachvollziehen welchen Weg Sie auf unserer Seite zur&uuml;cklegen und welche Links Sie anklicken.</li>
    <li class="adsimple-312060447">Conversionsberichte: Conversion nennt man einen Vorgang, bei dem Sie aufgrund einer Marketing-Botschaft eine gew&uuml;nschte Handlung ausf&uuml;hren. Zum Beispiel, wenn Sie von einem reinen Websitebesucher zu einem K&auml;ufer oder Newsletter-Abonnent werden. Mithilfe dieser Berichte erfahren wir mehr dar&uuml;ber, wie unsere Marketing-Ma&szlig;nahmen bei Ihnen ankommen. So wollen wir unsere Conversionrate steigern.</li>
    <li class="adsimple-312060447">Echtzeitberichte: Hier erfahren wir immer sofort, was gerade auf unserer Website passiert. Zum Beispiel sehen wir wie viele User gerade diesen Text lesen.</li>
</ul>
<h3 class="adsimple-312060447">Warum verwenden wir Google Analytics auf unserer Website?</h3>
<p>Unser Ziel mit dieser Website ist klar: Wir wollen Ihnen das bestm&ouml;gliche Service bieten. Die Statistiken und Daten von Google Analytics helfen uns dieses Ziel zu erreichen.</p>
<p>Die statistisch ausgewerteten Daten zeigen uns ein klares Bild von den St&auml;rken und Schw&auml;chen unserer Website. Einerseits k&ouml;nnen wir unsere Seite so optimieren, dass sie von interessierten Menschen auf Google leichter gefunden wird. Andererseits helfen uns die Daten, Sie als Besucher besser zu verstehen. Wir wissen somit sehr genau, was wir an unserer Website verbessern m&uuml;ssen, um Ihnen das bestm&ouml;gliche Service zu bieten. Die Daten dienen uns auch, unsere Werbe- und Marketing-Ma&szlig;nahmen individueller und kosteng&uuml;nstiger durchzuf&uuml;hren. Schlie&szlig;lich macht es nur Sinn, unsere Produkte und Dienstleistungen Menschen zu zeigen, die sich daf&uuml;r interessieren.</p>
<h3 class="adsimple-312060447">Welche Daten werden von Google Analytics gespeichert?</h3>
<p>Google Analytics erstellt mithilfe eines Tracking-Codes eine zuf&auml;llige, eindeutige ID, die mit Ihrem Browser-Cookie verbunden ist. So erkennt Sie Google Analytics als neuen User. Wenn Sie das n&auml;chste Mal unsere Seite besuchen, werden Sie als &bdquo;wiederkehrender&ldquo; User erkannt. Alle gesammelten Daten werden gemeinsam mit dieser User-ID gespeichert. So ist es &uuml;berhaupt erst m&ouml;glich pseudonyme Userprofile auszuwerten.</p>
<p>Um mit Google Analytics unsere Website analysieren zu k&ouml;nnen, muss eine Property-ID in den Tracking-Code eingef&uuml;gt werden. Die Daten werden dann in der entsprechenden Property gespeichert. F&uuml;r jede neu angelegte Property ist die Google Analytics 4-Property standardm&auml;&szlig;ig. Alternativ kann man aber auch noch die Universal Analytics Property erstellen. Je nach verwendeter Property werden Daten unterschiedlich lange gespeichert.</p>
<p>Durch Kennzeichnungen wie Cookies und App-Instanz-IDs werden Ihre Interaktionen auf unserer Website gemessen. Interaktionen sind alle Arten von Handlungen, die Sie auf unserer Website ausf&uuml;hren. Wenn Sie auch andere Google-Systeme (wie z.B. ein Google-Konto) n&uuml;tzen, k&ouml;nnen &uuml;ber Google Analytics generierte Daten mit Drittanbieter-Cookies verkn&uuml;pft werden. Google gibt keine Google Analytics-Daten weiter, au&szlig;er wir als Websitebetreiber genehmigen das. Zu Ausnahmen kann es kommen, wenn es gesetzlich erforderlich ist.</p>
<p>Folgende Cookies werden von Google Analytics verwendet:</p>
<p><strong class="adsimple-312060447">Name:</strong> _ga<br /><strong class="adsimple-312060447">Wert:&nbsp;</strong>2.1326744211.152312060447-5<br /><strong class="adsimple-312060447">Verwendungszweck:</strong> Standardm&auml;&szlig;ig verwendet analytics.js das Cookie _ga, um die User-ID zu speichern. Grunds&auml;tzlich dient es zur Unterscheidung der Webseitenbesucher.<br /><strong class="adsimple-312060447">Ablaufdatum:</strong> nach 2 Jahren</p>
<p><strong class="adsimple-312060447">Name:</strong> _gid<br /><strong class="adsimple-312060447">Wert:&nbsp;</strong>2.1687193234.152312060447-1<br /><strong class="adsimple-312060447">Verwendungszweck:</strong> Das Cookie dient auch zur Unterscheidung der Webseitenbesucher<br /><strong class="adsimple-312060447">Ablaufdatum:</strong> nach 24 Stunden</p>
<p><strong class="adsimple-312060447">Name:</strong> _gat_gtag_UA_&lt;property-id&gt;<br /><strong class="adsimple-312060447">Wert:</strong> 1<br /><strong class="adsimple-312060447">Verwendungszweck:</strong> Wird zum Senken der Anforderungsrate verwendet. Wenn Google Analytics &uuml;ber den Google Tag Manager bereitgestellt wird, erh&auml;lt dieser Cookie den Namen _dc_gtm_ &lt;property-id&gt;.<br /><strong class="adsimple-312060447">Ablaufdatum:&nbsp;</strong>nach 1 Minute</p>
<p><strong class="adsimple-312060447">Name:</strong> AMP_TOKEN<br /><strong class="adsimple-312060447">Wert:</strong> keine Angaben<br /><strong class="adsimple-312060447">Verwendungszweck:</strong> Das Cookie hat einen Token, mit dem eine User ID vom AMP-Client-ID-Dienst abgerufen werden kann. Andere m&ouml;gliche Werte weisen auf eine Abmeldung, eine Anfrage oder einen Fehler hin.<br /><strong class="adsimple-312060447">Ablaufdatum:</strong> nach 30 Sekunden bis zu einem Jahr</p>
<p><strong class="adsimple-312060447">Name:</strong> __utma<br /><strong class="adsimple-312060447">Wert:&nbsp;</strong>1564498958.1564498958.1564498958.1<br /><strong class="adsimple-312060447">Verwendungszweck:</strong> Mit diesem Cookie kann man Ihr Verhalten auf der Website verfolgen und die Leistung messen. Das Cookie wird jedes Mal aktualisiert, wenn Informationen an Google Analytics gesendet werden.<br /><strong class="adsimple-312060447">Ablaufdatum:</strong> nach 2 Jahren</p>
<p><strong class="adsimple-312060447">Name:</strong> __utmt<br /><strong class="adsimple-312060447">Wert:</strong> 1<br /><strong class="adsimple-312060447">Verwendungszweck:</strong> Das Cookie wird wie _gat_gtag_UA_&lt;property-id&gt; zum Drosseln der Anforderungsrate verwendet.<br /><strong class="adsimple-312060447">Ablaufdatum:</strong> nach 10 Minuten</p>
<p><strong class="adsimple-312060447">Name:</strong> __utmb<br /><strong class="adsimple-312060447">Wert:&nbsp;</strong>3.10.1564498958<br /><strong class="adsimple-312060447">Verwendungszweck:</strong> Dieses Cookie wird verwendet, um neue Sitzungen zu bestimmen. Es wird jedes Mal aktualisiert, wenn neue Daten bzw. Infos an Google Analytics gesendet werden.<br /><strong class="adsimple-312060447">Ablaufdatum:</strong> nach 30 Minuten</p>
<p><strong class="adsimple-312060447">Name:</strong> __utmc<br /><strong class="adsimple-312060447">Wert:</strong> 167421564<br /><strong class="adsimple-312060447">Verwendungszweck:</strong> Dieses Cookie wird verwendet, um neue Sitzungen f&uuml;r wiederkehrende Besucher festzulegen. Dabei handelt es sich um ein Session-Cookie und wird nur solange gespeichert, bis Sie den Browser wieder schlie&szlig;en.<br /><strong class="adsimple-312060447">Ablaufdatum:</strong> Nach Schlie&szlig;ung des Browsers</p>
<p><strong class="adsimple-312060447">Name:</strong> __utmz<br /><strong class="adsimple-312060447">Wert:</strong> m|utmccn=(referral)|utmcmd=referral|utmcct=/<br /><strong class="adsimple-312060447">Verwendungszweck:</strong> Das Cookie wird verwendet, um die Quelle des Besucheraufkommens auf unserer Website zu identifizieren. Das hei&szlig;t, das Cookie speichert, von wo Sie auf unsere Website gekommen sind. Das kann eine andere Seite bzw. eine Werbeschaltung gewesen sein.<br /><strong class="adsimple-312060447">Ablaufdatum:</strong> nach 6 Monaten</p>
<p><strong class="adsimple-312060447">Name:</strong> __utmv<br /><strong class="adsimple-312060447">Wert:</strong> keine Angabe<br /><strong class="adsimple-312060447">Verwendungszweck:</strong> Das Cookie wird verwendet, um benutzerdefinierte Userdaten zu speichern. Es wird immer aktualisiert, wenn Informationen an Google Analytics gesendet werden.<br /><strong class="adsimple-312060447">Ablaufdatum:</strong> nach 2 Jahren</p>
<p><strong class="adsimple-312060447">Anmerkung:</strong> Diese Aufz&auml;hlung kann keinen Anspruch auf Vollst&auml;ndigkeit erheben, da Google die Wahl ihrer Cookies immer wieder auch ver&auml;ndert.</p>
<p>Hier zeigen wir Ihnen einen &Uuml;berblick &uuml;ber die wichtigsten Daten, die mit Google Analytics erhoben werden:</p>
<p><strong class="adsimple-312060447">Heatmaps:</strong> Google legt sogenannte Heatmaps an. &Uuml;ber Heatmaps sieht man genau jene Bereiche, die Sie anklicken. So bekommen wir Informationen, wo Sie auf unserer Seite &bdquo;unterwegs&ldquo; sind.</p>
<p><strong class="adsimple-312060447">Sitzungsdauer:</strong> Als Sitzungsdauer bezeichnet Google die Zeit, die Sie auf unserer Seite verbringen, ohne die Seite zu verlassen. Wenn Sie 20 Minuten inaktiv waren, endet die Sitzung automatisch.</p>
<p><strong class="adsimple-312060447">Absprungrate</strong> (engl. Bouncerate): Von einem Absprung ist die Rede, wenn Sie auf unserer Website nur eine Seite ansehen und dann unsere Website wieder verlassen.</p>
<p><strong class="adsimple-312060447">Kontoerstellung:</strong> Wenn Sie auf unserer Website ein Konto erstellen bzw. eine Bestellung machen, erhebt Google Analytics diese Daten.</p>
<p><strong class="adsimple-312060447">IP-Adresse:</strong> Die IP-Adresse wird nur in gek&uuml;rzter Form dargestellt, damit keine eindeutige Zuordnung m&ouml;glich ist.</p>
<p><strong class="adsimple-312060447">Standort:</strong> &Uuml;ber die IP-Adresse kann das Land und Ihr ungef&auml;hrer Standort bestimmt werden. Diesen Vorgang bezeichnet man auch als IP- Standortbestimmung.</p>
<p><strong class="adsimple-312060447">Technische Informationen:</strong> Zu den technischen Informationen z&auml;hlen unter anderem Ihr Browsertyp, Ihr Internetanbieter oder Ihre Bildschirmaufl&ouml;sung.</p>
<p><strong class="adsimple-312060447">Herkunftsquelle:</strong> Google Analytics beziehungsweise uns interessiert nat&uuml;rlich auch &uuml;ber welche Website oder welche Werbung Sie auf unsere Seite gekommen sind.</p>
<p>Weitere Daten sind Kontaktdaten, etwaige Bewertungen, das Abspielen von Medien (z. B. wenn Sie ein Video &uuml;ber unsere Seite abspielen), das Teilen von Inhalten &uuml;ber Social Media oder das Hinzuf&uuml;gen zu Ihren Favoriten. Die Aufz&auml;hlung hat keinen Vollst&auml;ndigkeitsanspruch und dient nur zu einer allgemeinen Orientierung der Datenspeicherung durch Google Analytics.</p>
<h3 class="adsimple-312060447">Wie lange und wo werden die Daten gespeichert?</h3>
<p>Google hat ihre Server auf der ganzen Welt verteilt. Die meisten Server befinden sich in Amerika und folglich werden Ihre Daten meist auf amerikanischen Servern gespeichert. Hier k&ouml;nnen Sie genau nachlesen wo sich die Google-Rechenzentren befinden: <a class="adsimple-312060447" href="https://www.google.com/about/datacenters/locations/?hl=de">https://www.google.com/about/datacenters/inside/locations/?hl=de</a></p>
<p>Ihre Daten werden auf verschiedenen physischen Datentr&auml;gern verteilt. Das hat den Vorteil, dass die Daten schneller abrufbar sind und vor Manipulation besser gesch&uuml;tzt sind. In jedem Google-Rechenzentrum gibt es entsprechende Notfallprogramme f&uuml;r Ihre Daten. Wenn beispielsweise die Hardware bei Google ausf&auml;llt oder Naturkatastrophen Server lahmlegen, bleibt das Risiko einer Dienstunterbrechung bei Google dennoch gering.</p>
<p>Die Aufbewahrungsdauer der Daten h&auml;ngt von den verwendeten Properties ab. Bei der Verwendung der neueren Google Analytics 4-Properties ist die Aufbewahrungsdauer Ihrer Userdaten auf 14 Monate fix eingestellt. F&uuml;r andere sogenannte Ereignisdaten haben wir die M&ouml;glichkeit eine Aufbewahrungsdauer von 2 Monaten oder 14 Monaten zu w&auml;hlen.</p>
<p>Bei Universal Analytics-Properties ist bei Google Analytics eine Aufbewahrungsdauer Ihrer Userdaten von 26 Monaten standardisiert eingestellt. Dann werden Ihre Userdaten gel&ouml;scht. Allerdings haben wir die M&ouml;glichkeit, die Aufbewahrungsdauer von Nutzdaten selbst zu w&auml;hlen. Daf&uuml;r stehen uns f&uuml;nf Varianten zur Verf&uuml;gung:</p>
<ul class="adsimple-312060447">
    <li class="adsimple-312060447">L&ouml;schung nach 14 Monaten</li>
    <li class="adsimple-312060447">L&ouml;schung nach 26 Monaten</li>
    <li class="adsimple-312060447">L&ouml;schung nach 38 Monaten</li>
    <li class="adsimple-312060447">L&ouml;schung nach 50 Monaten</li>
    <li class="adsimple-312060447">Keine automatische L&ouml;schung</li>
</ul>
<p>Zus&auml;tzlich gibt es auch die Option, dass Daten erst dann gel&ouml;scht werden, wenn Sie innerhalb des von uns gew&auml;hlten Zeitraums nicht mehr unsere Website besuchen. In diesem Fall wird die Aufbewahrungsdauer jedes Mal zur&uuml;ckgesetzt, wenn Sie unsere Website innerhalb des festgelegten Zeitraums wieder besuchen.</p>
<p>Wenn der festgelegte Zeitraum abgelaufen ist, werden einmal im Monat die Daten gel&ouml;scht. Diese Aufbewahrungsdauer gilt f&uuml;r Ihre Daten, die mit Cookies, Usererkennung und Werbe-IDs (z.B. Cookies der DoubleClick-Domain) verkn&uuml;pft sind. Berichtergebnisse basieren auf aggregierten Daten und werden unabh&auml;ngig von Nutzerdaten gespeichert. Aggregierte Daten sind eine Zusammenschmelzung von Einzeldaten zu einer gr&ouml;&szlig;eren Einheit.</p>
<h3 class="adsimple-312060447">Wie kann ich meine Daten l&ouml;schen bzw. die Datenspeicherung verhindern?</h3>
<p>Nach dem Datenschutzrecht der Europ&auml;ischen Union haben Sie das Recht, Auskunft &uuml;ber Ihre Daten zu erhalten, sie zu aktualisieren, zu l&ouml;schen oder einzuschr&auml;nken. Mithilfe des Browser-Add-ons zur Deaktivierung von Google Analytics-JavaScript (ga.js, analytics.js, dc.js) verhindern Sie, dass Google Analytics Ihre Daten verwendet. Das Browser-Add-on k&ouml;nnen Sie unter <a class="adsimple-312060447" href="https://tools.google.com/dlpage/gaoptout?hl=de" rel="noopener noreferrer" target="_blank">https://tools.google.com/dlpage/gaoptout?hl=de</a> runterladen und installieren. Beachten Sie bitte, dass durch dieses Add-on nur die Datenerhebung durch Google Analytics deaktiviert wird.</p>
<p>Falls Sie grunds&auml;tzlich Cookies deaktivieren, l&ouml;schen oder verwalten wollen, finden Sie unter dem Abschnitt &bdquo;Cookies&ldquo; die entsprechenden Links zu den jeweiligen Anleitungen der bekanntesten Browser.</p>
<h3 class="adsimple-312060447">Rechtsgrundlage</h3>
<p>Der Einsatz von Google Analytics setzt Ihre Einwilligung voraus, welche wir mit unserem Cookie Popup eingeholt haben. Diese Einwilligung stellt laut<strong class="adsimple-312060447">&nbsp;Art. 6 Abs. 1 lit. a DSGVO (Einwilligung)</strong> die Rechtsgrundlage f&uuml;r die Verarbeitung personenbezogener Daten, wie sie bei der Erfassung durch Web-Analytics Tools vorkommen kann, dar.</p>
<p>Zus&auml;tzlich zur Einwilligung besteht von unserer Seite ein berechtigtes Interesse daran, dass Verhalten der Websitebesucher zu analysieren und so unser Angebot technisch und wirtschaftlich zu verbessern. Mit Hilfe von Google Analytics erkennen wir Fehler der Website, k&ouml;nnen Attacken identifizieren und die Wirtschaftlichkeit verbessern. Die Rechtsgrundlage daf&uuml;r ist <strong class="adsimple-312060447">Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</strong>. Wir setzen Google Analytics gleichwohl nur ein, soweit Sie eine Einwilligung erteilt haben.</p>
<p>Google verarbeitet Daten von Ihnen u.a. auch in den USA. Wir weisen darauf hin, dass nach Meinung des Europ&auml;ischen Gerichtshofs derzeit kein angemessenes Schutzniveau f&uuml;r den Datentransfer in die USA besteht. Dies kann mit verschiedenen Risiken f&uuml;r die Rechtm&auml;&szlig;igkeit und Sicherheit der Datenverarbeitung einhergehen.</p>
<p>Als Grundlage der Datenverarbeitung bei Empf&auml;ngern mit Sitz in Drittstaaten (au&szlig;erhalb der Europ&auml;ischen Union, Island, Liechtenstein, Norwegen, also insbesondere in den USA) oder einer Datenweitergabe dorthin verwendet Google sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses &ndash; SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass Ihre Daten auch dann den europ&auml;ischen Datenschutzstandards entsprechen, wenn diese in Drittl&auml;nder (wie beispielsweise in die USA) &uuml;berliefert und dort gespeichert werden. Durch diese Klauseln verpflichtet sich Google, bei der Verarbeitung Ihrer relevanten Daten, das europ&auml;ische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchf&uuml;hrungsbeschluss der EU-Kommission. Sie finden den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a class="adsimple-312060447" href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de" rel="noopener" target="_blank">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a></p>
<p>Die Google Ads Datenverarbeitungsbedingungen (Google Ads Data Processing Terms), welche den Standardvertragsklauseln entsprechen und auch f&uuml;r Google Analytics geltend sind, finden Sie unter <a class="adsimple-312060447" href="https://business.safety.google/adsprocessorterms/">https://business.safety.google/adsprocessorterms/</a>.</p>
<p>Wir hoffen, wir konnten Ihnen die wichtigsten Informationen rund um die Datenverarbeitung von Google Analytics n&auml;herbringen. Wenn Sie mehr &uuml;ber den Tracking-Dienst erfahren wollen, empfehlen wir diese beiden Links: <a class="adsimple-312060447" href="https://marketingplatform.google.com/about/analytics/terms/de/">https://marketingplatform.google.com/about/analytics/terms/de/</a> und <a class="adsimple-312060447" href="https://support.google.com/analytics/answer/6004245?hl=de" rel="noopener noreferrer" target="_blank">https://support.google.com/analytics/answer/6004245?hl=de</a>.</p>
<h2 class="adsimple-312060447" id="google-analytics-ip-anonymisierung">Google Analytics IP-Anonymisierung</h2>
<p>Wir haben auf dieser Webseite die IP-Adressen-Anonymisierung von Google Analytics implementiert.&nbsp;Diese Funktion wurde von Google entwickelt, damit diese Webseite die geltenden Datenschutzbestimmungen und Empfehlungen der lokalen Datenschutzbeh&ouml;rden einhalten kann, wenn diese eine Speicherung der vollst&auml;ndigen IP-Adresse untersagen. Die Anonymisierung bzw. Maskierung der IP findet statt, sobald die IP-Adressen im Google Analytics-Datenerfassungsnetzwerk eintreffen und bevor eine Speicherung oder Verarbeitung der Daten stattfindet.</p>
<p>Mehr Informationen zur IP-Anonymisierung finden Sie auf&nbsp;<a class="adsimple-312060447" href="https://support.google.com/analytics/answer/2763052?hl=de" rel="noopener" target="_blank">https://support.google.com/analytics/answer/2763052?hl=de</a>.</p>
<h2 class="adsimple-312060447" id="google-analytics-ohne-cookies">Google Analytics ohne Cookies</h2>
<p>Wir nutzen auf unserer Website zwar Google Analytics (kurz GA), allerdings ohne Cookies in Ihrem Browser zu setzen. Was Cookies sind, haben wir bereits weiter oben erkl&auml;rt, hoffentlich sind die Ausf&uuml;hrungen noch im Ged&auml;chtnis. Nur kurz und konkret auf GA bezogen: Durch Cookies werden f&uuml;r GA hilfreiche Daten in Ihrem Browser auf Ihrem Ger&auml;t gespeichert. Durch den Entfall der Nutzung von Cookies werden gerade keine personenbezogenen Daten in solchen Cookies gespeichert, die ein Nutzerprofil begr&uuml;nden werden. Google Analytics kann zwar diverse Messungen und Webanalysen durchf&uuml;hren, jedoch werden die daf&uuml;r erhobenen Daten lediglich auf den Google-Servern gespeichert und Ihre Privatsph&auml;re wird deutlich st&auml;rker respektiert und gesch&uuml;tzt.</p>
<h2 class="adsimple-312060447" id="partnerprogramme-einleitung">Partnerprogramme Einleitung</h2>
<table border="1" cellpadding="15">
    <tbody>
        <tr>
            <td><strong class="adsimple-312060447">Partnerprogramme Datenschutzerkl&auml;rung Zusammenfassung</strong><br />👥 Betroffene: Besucher der Website<br />🤝 Zweck: wirtschaftlicher Erfolg und die Optimierung unserer Serviceleistung.<br />📓 Verarbeitete Daten: Zugriffsstatistiken, die Daten wie Standorte der Zugriffe, Ger&auml;tedaten, Zugriffsdauer und Zeitpunkt, Navigationsverhalten, Klickverhalten und IP-Adressen enthalten. Auch personenbezogene Daten wie Name oder E-Mail-Adresse k&ouml;nnen verarbeitet werden.<br />📅 Speicherdauer: pers&ouml;nliche Daten werden meist von Partnerprogrammen gespeichert, bis diese nicht mehr ben&ouml;tigt werden<br />⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</td>
        </tr>
    </tbody>
</table>
<h3 class="adsimple-312060447">Was sind Partnerprogramme?</h3>
<p>Wir verwenden auf unserer Website Partnerprogramme unterschiedlicher Anbieter. Durch die Verwendung eines Partnerprogramms k&ouml;nnen Daten von Ihnen an den jeweiligen Partnerprogramm-Anbieter &uuml;bertragen, gespeichert und verarbeitet werden. In diesem Datenschutztext geben wir Ihnen einen allgemeinen &Uuml;berblick &uuml;ber die Datenverarbeitung durch Partnerprogramme und zeigen Ihnen wie Sie eine Daten&uuml;bertragung auch unterbinden bzw. widerrufen k&ouml;nnen. Jedes Partnerprogramm (auch Affiliateprogramm genannt) basiert auf dem Prinzip der Vermittlungsprovision. Auf unserer Website wird ein Link oder eine Werbung samt Link gesetzt und wenn Sie sich daf&uuml;r interessieren und diesen anklicken und ein Produkt oder eine Dienstleistung auf diesem Wege erwerben, erhalten wir daf&uuml;r eine Provision (Werbekostenerstattung)</p>
<h3 class="adsimple-312060447">Warum verwenden wir Partnerprogramme auf unserer Website?</h3>
<p>Unser Ziel ist es, Ihnen eine angenehme Zeit mit vielen hilfreichen Inhalten zu liefern. Daf&uuml;r stecken wir sehr viel Arbeit und Zeit in die Entwicklung unserer Website. Mit Hilfe von Partnerprogrammen haben wir die M&ouml;glichkeit, f&uuml;r unsere Arbeit auch ein bisschen entlohnt zu werden. Jeder Partnerlink hat selbstverst&auml;ndlich immer mit unserem Thema zu tun und zeigt Angebote, die Sie interessieren k&ouml;nnten.</p>
<h3 class="adsimple-312060447">Welche Daten werden verarbeitet?</h3>
<p>Damit nachvollzogen werden kann, ob Sie einen von uns eingesetzten Link angeklickt haben, muss der Partnerprogramm-Anbieter erfahren, dass Sie es waren, die &uuml;ber unsere Website den Link gefolgt ist. Es muss also eine korrekte Zuordnung der eingesetzten Partnerprogramm-Links zu den folgenden Aktionen (Gesch&auml;ftsabschluss, Kauf, Conversion, Impression usw.) erfolgen. Nur dann kann auch die Abrechnung der Provisionen funktionieren.</p>
<p>Damit diese Zuordnung funktioniert, kann einem Link ein Wert angeh&auml;ngt werden (in der URL) oder Informationen in Cookies gespeichert werden. Darin wird etwa gespeichert, von welcher Seite Sie kommen (Referrer), wann Sie auf den Link geklickt haben, eine Kennung unserer Website, um welches Angebot es sich handelt und eine User-Kennung.</p>
<p>Das bedeutet, sobald Sie mit Produkten und Dienstleistungen eines Partnerprogramms interagieren, erhebt dieser Anbieter auch Daten von Ihnen. Welche Daten genau gespeichert werden, h&auml;ngt von den einzelnen Anbietern ab. Beispielsweise unterscheidet das Amazon-Partnerprogramm zwischen aktiven und automatischen Informationen. Zu den aktiven z&auml;hlen Name, E-Mail-Adresse, Telefonnummer, Alter, Zahlungsinformationen oder Standortinformationen. Zu den automatisch gespeicherten Informationen z&auml;hlen in diesem Fall Userverhalten, IP-Adresse, Ger&auml;teinformationen und die URL.</p>
<h3 class="adsimple-312060447">Dauer der Datenverarbeitung</h3>
<p>&Uuml;ber die Dauer der Datenverarbeitung informieren wir Sie weiter unten, sofern wir weitere Informationen dazu haben. Generell werden personenbezogene Daten nur so lange verarbeitet, wie es f&uuml;r die Bereitstellung der Dienstleistungen und Produkte notwendig ist. Daten, die in Cookies gespeichert werden, werden unterschiedlich lange gespeichert. Manche Cookies werden bereits nach dem Verlassen der Website wieder gel&ouml;scht, andere k&ouml;nnen, sofern diese nicht aktiv gel&ouml;scht werden, &uuml;ber einige Jahre in Ihrem Browser gespeichert sein. Die genaue Dauer der Datenverarbeitung h&auml;ngt vom verwendeten Anbieter ab, meistens sollten Sie sich auf eine Speicherdauer von mehreren Jahren einstellen. In den jeweiligen Datenschutzerkl&auml;rungen der einzelnen Anbieter erhalten Sie in der Regel genaue Informationen &uuml;ber die Dauer der Datenverarbeitung.</p>
<h3 class="adsimple-312060447">Widerspruchsrecht</h3>
<p>Sie haben immer das Recht auf Auskunft, Berichtigung und L&ouml;schung Ihrer personenbezogenen Daten. Bei Fragen k&ouml;nnen Sie auch jederzeit Verantwortliche des verwendeten Partnerprogramm-Anbieters kontaktieren. Kontaktdaten finden Sie entweder in unserer spezifischen Datenschutzerkl&auml;rung oder auf der Website des entsprechenden Anbieters.</p>
<p>Cookies, die Anbieter f&uuml;r ihre Funktionen verwenden, k&ouml;nnen Sie in Ihrem Browser l&ouml;schen, deaktivieren oder verwalten. Je nachdem welchen Browser Sie verwenden, funktioniert dies auf unterschiedliche Art und Weise.</p>
<h3 class="adsimple-312060447">Rechtsgrundlage</h3>
<p>Wenn Sie eingewilligt haben, dass Partnerprogramme eingesetzt werden d&uuml;rfen, ist die Rechtsgrundlage der entsprechenden Datenverarbeitung diese Einwilligung. Diese Einwilligung stellt laut <strong class="adsimple-312060447">Art. 6 Abs. 1 lit. a DSGVO (Einwilligung)</strong> die Rechtsgrundlage f&uuml;r die Verarbeitung personenbezogener Daten, wie sie bei der Erfassung durch ein Partnerprogramm vorkommen kann, dar.</p>
<p>Von unserer Seite besteht zudem ein berechtigtes Interesse, ein Partnerprogramm zu verwenden, um unser Online-Service und unsere Marketingma&szlig;nahmen zu optimieren. Die daf&uuml;r entsprechende Rechtsgrundlage ist <strong class="adsimple-312060447">Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</strong>. Wir setzen das Partnerprogramm gleichwohl nur ein, soweit Sie eine Einwilligung erteilt haben.</p>
<p>Informationen zu speziellen Partnerprogramme, erhalten Sie &ndash; sofern vorhanden &ndash; in den folgenden Abschnitten.</p>
<h2 class="adsimple-312060447" id="amazon-partnerprogramm-datenschutzerklaerung">Amazon-Partnerprogramm Datenschutzerkl&auml;rung</h2>
<table border="1" cellpadding="15">
    <tbody>
        <tr>
            <td><strong class="adsimple-312060447">Amazon-Partnerprogramm Datenschutzerkl&auml;rung Zusammenfassung</strong><br />👥 Betroffene: Besucher der Website<br />🤝 Zweck: wirtschaftlicher Erfolg und die Optimierung unserer Serviceleistung.<br />📓 Verarbeitete Daten: Zugriffsstatistiken, die Daten wie Standorte der Zugriffe, Ger&auml;tedaten, Zugriffsdauer und Zeitpunkt, Navigationsverhalten, Klickverhalten und IP-Adressen enthalten. Auch personenbezogene Daten wie Name oder E-Mail-Adresse k&ouml;nnen verarbeitet werden.<br />📅 Speicherdauer: pers&ouml;nliche Daten werden von Amazon gespeichert, bis diese nicht mehr ben&ouml;tigt werden<br />⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit.f DSGVO (Berechtigte Interessen)</td>
        </tr>
    </tbody>
</table>
<h3 class="adsimple-312060447">Was ist das Amazon-Partnerprogramm?</h3>
<p>Wir verwenden auf unserer Website das Amazon-Partnerprogramm des Unternehmens Amazon.com, Inc. Die verantwortlichen Stellen im Sinne der Datenschutzerkl&auml;rung sind die Amazon Europe Core S.&agrave;.r.l., die Amazon EU S.&agrave;.r.l, die Amazon Services Europe S.&agrave;.r.l. und die Amazon Media EU S.&agrave;.r.l., alle vier ans&auml;ssig 5, Rue Plaetis, L-2338 Luxemburg sowie Amazon Instant Video Germany GmbH, Domagkstr. 28, 80807 M&uuml;nchen. Als Datenverarbeiter wird die Amazon Deutschland Services GmbH, Marcel-Breuer-Str. 12, 80807 M&uuml;nchen, t&auml;tig. Durch die Verwendung dieses Amazon-Partnerprogramms k&ouml;nnen Daten von Ihnen an Amazon &uuml;bertragen, gespeichert und verarbeitet werden.</p>
<p>In dieser Datenschutzerkl&auml;rung informieren wir Sie um welche Daten es sich handelt, warum wir das Programm verwenden und wie Sie die Daten&uuml;bertragung verwalten bzw. unterbinden k&ouml;nnen.</p>
<p>Das Amazon-Partnerprogramm ist ein Affiliate-Marketing-Programm des Online-Versandunternehmens <a class="adsimple-312060447" href="https://www.amazon.de/ref=as_li_ss_tl?site-redirect=at&linkCode=ll2&tag=thetraffic-21&linkId=16a65fb03b8cb39206283c5345d87944&language=de_DE&tid=312060447" rel="follow noopener noreferrer" target="_blank">Amazon.de</a>. Wie jedes Affiliate-Programm basiert auch das Amazon-Partnerprogramm auf dem Prinzip der Vermittlungsprovision. Amazon bzw. wir platzieren auf unserer Website Werbung oder Partnerlinks und wenn Sie darauf klicken und ein Produkt &uuml;ber Amazon kaufen, erhalten wir eine Werbekostenerstattung (Provision).</p>
<h3 class="adsimple-312060447">Warum verwenden wir das Amazon-Partnerprogramm auf unserer Website?</h3>
<p>Unser Ziel ist es Ihnen eine angenehme Zeit mit vielen hilfreichen Inhalten zu liefern. Daf&uuml;r stecken wir sehr viel Arbeit und Energie in die Entwicklung unserer Website. Mit Hilfe des Amazon-Partnerprogramms haben wir die M&ouml;glichkeit, f&uuml;r unsere Arbeit auch ein bisschen entlohnt zu werden. Jeder Partnerlink zu Amazon hat selbstverst&auml;ndlich immer mit unserem Thema zu tun und zeigt Angebote, die Sie interessieren k&ouml;nnten.</p>
<h3 class="adsimple-312060447">Welche Daten werden durch das Amazon-Partnerprogramm gespeichert?</h3>
<p>Sobald Sie mit den Produkten und Dienstleistungen von Amazon interagieren, erhebt Amazon Daten von Ihnen. Amazon unterscheidet zwischen Informationen, die Sie aktiv dem Unternehmen geben und Informationen, die automatisch gesammelt und gespeichert werden. Zu den &ldquo;aktiven Informationen&rdquo; z&auml;hlen zum Beispiel Name, E-Mail-Adresse, Telefonnummer, Alter, Zahlungsinformationen oder Standortinformationen. Sogenannte &bdquo;automatische Informationen&ldquo; werden in erster Linie &uuml;ber Cookies gespeichert. Dazu z&auml;hlen Informationen zum Userverhalten, IP-Adresse, Ger&auml;tinformationen (Browsertyp, Standort, Betriebssysteme) oder die URL. Amazon speichert weiters auch den Clickstream. Damit ist der Weg (Reihenfolge der Seiten) gemeint, den Sie als User zur&uuml;cklegen, um zu einem Produkt zu kommen. Auch um die Herkunft einer Bestellung nachvollziehen zu k&ouml;nnen, speichert Amazon Cookies in Ihrem Browser. So erkennt das Unternehmen, dass Sie &uuml;ber unsere Website eine Amazon-Werbeanzeige oder einen Partnerlink angeklickt haben.</p>
<p>Wenn Sie ein Amazon-Konto haben und angemeldet sind, w&auml;hrend Sie auf unserer Webseite surfen, k&ouml;nnen die erhobenen Daten Ihrem Account zugewiesen werden. Das verhindern Sie, indem Sie sich bei Amazon abmelden, bevor Sie auf unserer Website surfen.</p>
<p>Hier zeigen wir Ihnen beispielhafte Cookies, die in Ihrem Browser gesetzt werden, wenn Sie auf unserer Website auf einen Amazon-Link klicken.</p>
<p><strong class="adsimple-312060447">Name</strong>: uid<br /><strong class="adsimple-312060447">Wert:&nbsp;</strong>3230928052675285215312060447-9<br /><strong class="adsimple-312060447">Verwendungszweck:&nbsp;</strong>Dieses Cookie speichert eine eindeutige User-ID und sammelt Informationen &uuml;ber Ihre Websiteaktivit&auml;t.<br /><strong class="adsimple-312060447">Ablaufdatum:</strong> nach 2 Monaten</p>
<p><strong class="adsimple-312060447">Name</strong>: ad-id<br /><strong class="adsimple-312060447">Wert:&nbsp;</strong>AyDaInRV1k-Lk59xSnp7h5o<br /><strong class="adsimple-312060447">Verwendungszweck:&nbsp;</strong>Dieses Cookie wird von amazon-adsystem.com zur Verf&uuml;gung gestellt und dient dem Unternehmen f&uuml;r verschiedene Werbezwecke.<br /><strong class="adsimple-312060447">Ablaufdatum:</strong> nach 8 Monaten</p>
<p><strong class="adsimple-312060447">Name</strong>:&nbsp;uuid2<br /><strong class="adsimple-312060447">Wert:&nbsp;</strong>8965834524520213028312060447-2<br /><strong class="adsimple-312060447">Verwendungszweck:&nbsp;</strong>Dieses Cookie erm&ouml;glicht gezielte und interessensbezogene Werbung &uuml;ber die AppNexus-Plattform. Das Cookie sammelt und speichert &uuml;ber die IP-Adresse beispielsweise anonyme Daten dar&uuml;ber, welche Werbung Sie angeklickt haben und welche Seiten Sie aufgerufen haben.<br /><strong class="adsimple-312060447">Ablaufdatum:</strong> nach 3 Monaten</p>
<p><strong class="adsimple-312060447">Name</strong>:&nbsp;session-id<br /><strong class="adsimple-312060447">Wert:&nbsp;</strong>262-0272718-2582202312060447-1<br /><strong class="adsimple-312060447">Verwendungszweck:&nbsp;</strong>Dieses Cookie speichert eine eindeutige User-ID, die der Server Ihnen f&uuml;r die Dauer eines Websitebesuchs (Session) zuweist. Besuchen Sie dieselbe Seite wieder, werden die darin gespeichert Information wieder abgerufen.<br /><strong class="adsimple-312060447">Ablaufdatum:</strong> nach 15 Jahren</p>
<p><strong class="adsimple-312060447">Name</strong>:&nbsp;APID<br /><strong class="adsimple-312060447">Wert:&nbsp;</strong>UP9801199c-4bee-11ea-931d-02e8e13f0574<br /><strong class="adsimple-312060447">Verwendungszweck:&nbsp;</strong>Dieses Cookie speichert Informationen dar&uuml;ber, wie Sie eine Website nutzen und welche Werbung Sie vor dem Websitebesuch angesehen haben.<br /><strong class="adsimple-312060447">Ablaufdatum:</strong> nach einem Jahr</p>
<p><strong class="adsimple-312060447">Name</strong>: session-id-time<br /><strong class="adsimple-312060447">Wert:&nbsp;</strong>tb:s-STNY7ZS65H5335FZEVPE|1581329862486&amp;t:1581329864300&amp;adb:adblk_no<br /><strong class="adsimple-312060447">Verwendungszweck:&nbsp;</strong>Dieses Cookie erfasst die Zeit, die Sie mit einer eindeutigen Cookie-ID auf einer Webseite verbringen.<br /><strong class="adsimple-312060447">Ablaufdatum:</strong> nach 2 Jahren</p>
<p><strong class="adsimple-312060447">Name</strong>: csm-hit<br /><strong class="adsimple-312060447">Wert:&nbsp;</strong>2082754801l<br /><strong class="adsimple-312060447">Verwendungszweck:&nbsp;</strong>Wir k&ouml;nnten &uuml;ber dieses Cookie keine genauen Informationen in Erfahrung bringen.<br /><strong class="adsimple-312060447">Ablaufdatum:</strong> nach 15 Jahren</p>
<p><strong class="adsimple-312060447">Anmerkung:&nbsp;</strong>Bitte beachten Sie, dass diese Liste lediglich Cookie-Beispiele zeigt und keinen Anspruch auf Vollst&auml;ndigkeit erheben kann.</p>
<p>Amazon nutzt diese erhaltenen Informationen, um Werbeanzeigen genauer an die Interessen der User abzustimmen.</p>
<h3 class="adsimple-312060447">Wie lange und wo werden die Daten gespeichert?</h3>
<p>Pers&ouml;nliche Daten werden von Amazon so lange gespeichert, wie es f&uuml;r die gesch&auml;ftlichen Dienste von Amazon erforderlich ist oder aus rechtlichen Gr&uuml;nden notwendig ist. Da das Unternehmen Amazon seinen Hauptsitz in den USA hat, werden die gesammelten Daten auch auf amerikanischen Servern gespeichert.</p>
<h3 class="adsimple-312060447">Wie kann ich meine Daten l&ouml;schen bzw. die Datenspeicherung verhindern?</h3>
<p>Sie haben jederzeit das Recht auf Ihre personenbezogenen Daten zuzugreifen und sie auch zu l&ouml;schen. Wenn Sie einen Amazon-Account besitzen, k&ouml;nnen Sie in Ihrem Account viele der erhobenen Daten verwalten oder l&ouml;schen.</p>
<p>Eine weitere Option, die Datenverarbeitung und -speicherung durch Amazon nach Ihren Vorlieben zu verwalten, bietet Ihr Browser. Dort k&ouml;nnen Sie Cookies verwalten, deaktivieren oder l&ouml;schen. Dies funktioniert bei jedem Browser ein bisschen anders. Unter dem Abschnitt &bdquo;Cookies&ldquo; finden Sie die entsprechenden Links zu den jeweiligen Anleitungen der bekanntesten Browser.</p>
<h3 class="adsimple-312060447">Rechtsgrundlage</h3>
<p>Wenn Sie eingewilligt haben, dass das Amazon-Partnerprogramm eingesetzt werden darf, ist die Rechtsgrundlage der entsprechenden Datenverarbeitung diese Einwilligung. Diese Einwilligung stellt laut<strong class="adsimple-312060447">&nbsp;Art. 6 Abs. 1 lit. a DSGVO (Einwilligung)</strong> die Rechtsgrundlage f&uuml;r die Verarbeitung personenbezogener Daten, wie sie bei der Erfassung durch das Amazon-Partnerprogramm vorkommen kann, dar.</p>
<p>Von unserer Seite besteht zudem ein berechtigtes Interesse, das Amazon-Partnerprogramm zu verwenden, um unser Online-Service und unsere Marketingma&szlig;nahmen zu optimieren. Die daf&uuml;r entsprechende Rechtsgrundlage ist <strong class="adsimple-312060447">Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</strong>. Wir setzen das Amazon-Partnerprogramm gleichwohl nur ein, soweit Sie eine Einwilligung erteilt haben.</p>
<p>Amazon verarbeitet Daten von Ihnen u.a. auch in den USA. Wir weisen darauf hin, dass nach Meinung des Europ&auml;ischen Gerichtshofs derzeit kein angemessenes Schutzniveau f&uuml;r den Datentransfer in die USA besteht. Dies kann mit verschiedenen Risiken f&uuml;r die Rechtm&auml;&szlig;igkeit und Sicherheit der Datenverarbeitung einhergehen.</p>
<p>Als Grundlage der Datenverarbeitung bei Empf&auml;ngern mit Sitz in Drittstaaten (au&szlig;erhalb der Europ&auml;ischen Union, Island, Liechtenstein, Norwegen, also insbesondere in den USA) oder einer Datenweitergabe dorthin verwendet Amazon sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses &ndash; SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass Ihre Daten auch dann den europ&auml;ischen Datenschutzstandards entsprechen, wenn diese in Drittl&auml;nder (wie beispielsweise in die USA) &uuml;berliefert und dort gespeichert werden. Durch diese Klauseln verpflichtet sich Amazon, bei der Verarbeitung Ihrer relevanten Daten, das europ&auml;ische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchf&uuml;hrungsbeschluss der EU-Kommission. Sie finden den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a class="adsimple-312060447" href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de" rel="follow noopener" target="_blank">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a></p>
<p>Die Amazon Datenverarbeitungsbedingung (AWS GDPR DATA PROCESSING), welche den Standardvertragsklauseln entsprechen, finden Sie unter <a class="adsimple-312060447" href="https://d1.awsstatic.com/legal/aws-gdpr/AWS_GDPR_DPA.pdf" rel="follow noopener" target="_blank">https://d1.awsstatic.com/legal/aws-gdpr/AWS_GDPR_DPA.pdf</a>.</p>
<p>Wir hoffen, wir haben Ihnen die wichtigsten Informationen &uuml;ber die Daten&uuml;bertragung durch die Verwendung von dem Amazon-Partnerprogramm n&auml;hergebracht. Mehr Informationen finden Sie unter <a class="adsimple-312060447" href="https://www.amazon.de/gp/help/customer/display.html?nodeId=201909010" rel="noopener noreferrer" target="_blank">https://www.amazon.de/gp/help/customer/display.html?nodeId=201909010</a>.</p>
<p>Alle Texte sind urheberrechtlich gesch&uuml;tzt.</p>
<p style={{marginTop: "15px"}}>Quelle: Erstellt mit dem <a href="https://www.adsimple.de/datenschutz-generator/" title="Datenschutz Generator von AdSimple für Deutschland">Datenschutz Generator</a> von AdSimple</p>
<p><br /></p>
</div>
  </Layout>
)

export default Datenschutz
